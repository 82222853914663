<template>
  <el-dialog
      v-if="endowmentsUploadDialog.show"
      :title="endowmentsUploadDialog.title"
      :visible.sync="endowmentsUploadDialog.show"
      center
      width="600px"
      top="20vh"
  >
    <div class="cont">
      <el-form
          ref="ruleForm"
          v-loading="fromloding"
          :model="ruleForm"
          :rules="ruleFormRules"
          label-width="150px"
          class="ruleForm"
          :validate-on-rule-change="true"
      >
        <el-form-item label="报名表上传：" prop="fileUrl">
          <div>
            <FileUpload
                :file-arr="ruleForm.fileUrl"
                @getUploadList="getFile"
                :issubmit="ruleForm.status === 1 || ruleForm.status===4"
            />
            <p class="img-tip">
              前往新疆专业技术人员管理平台(www.xjzcsq.com)
              <br>
              下载报名表并上传
            </p>
          </div>
        </el-form-item>
        <el-form-item label="申请备注：">
          <el-input
              type="textarea"
              v-model="ruleForm.remark"
              placeholder="请输入内容"
              :autosize="{ minRows: 2, maxRows: 4 }"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="审核状态：" v-if="ruleForm.status === 2 || ruleForm.status === 4">
          <el-tag v-if="ruleForm.status === 2">您的报名表正在审核中</el-tag>
          <el-tag v-if="ruleForm.status === 4" type="danger">您的报名表审核未通过！请上传正确的凭证重新提交审核</el-tag>
        </el-form-item>
        <el-form-item label="审核意见：" v-if="ruleForm.auditRemark !== null && ruleForm.auditRemark !== ''">
          <el-input
              type="textarea"
              disabled
              v-model="ruleForm.auditRemark"
              placeholder="请输入内容"
              :autosize="{ minRows: 2, maxRows: 4 }"
          >
          </el-input>
        </el-form-item>
        <div class="btn-wrap">
          <!-- <el-button style="width: 120px" @click="cancel">取消</el-button> -->
          <el-button :disabled="isDisabled" type="primary" v-if="ruleForm.status === 1 || ruleForm.status===4"
                     style="width: 120px" @click="btnSubmit"
          >提交审核
          </el-button
          >
        </div>
      </el-form>
      <!-- <div class="btn-wrap">
        <el-button @click="goBack" type="primary">关闭</el-button>
      </div> -->
    </div>
  </el-dialog>
</template>
<script>
import {uploadEndowments, endowmentsData} from "@/api/course";
import FileUpload from "@/components/Upload/fileUpload.vue";

export default {
  name: "EndowmentsUploadDialog",
  components: {
    FileUpload,
  },
  props: {
    endowmentsUploadDialog: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fileUrl: "", // 证书图片路径

      typeList: [], //证书类型数据
      typeId: null,
      certificateList: [], // 证书类别/专业数据
      certificateId: null,
      loading: false,
      fromloding: false,
      isDisabled: false,
      ruleForm: {
        fileUrl: "",
        courseId: null,
        remark: "",
        status: null,
        auditRemark: null
      },
      ruleFormRules: {},
    };
  },
  mounted() {
    this.ruleForm.courseId = this.endowmentsUploadDialog.courseId
    this.ruleForm.status = this.endowmentsUploadDialog.status
    if (this.endowmentsUploadDialog.status === 2 || this.endowmentsUploadDialog.status === 4) {
      this.getEndowmentsData();
    }
  },
  methods: {
    // 获取证书信息
    getEndowmentsData() {
      const obj = {
        courseId: this.endowmentsUploadDialog.courseId,
      };
      endowmentsData(obj).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            this.ruleForm.courseId = res.data.data.courseId;
            this.ruleForm.remark = res.data.data.auditRemark;
            // this.ruleForm.status = res.data.data.endowmentsStatus;
            this.ruleForm.fileUrl = res.data.data.uploadData;
            this.ruleForm.auditRemark = res.data.data.auditOpinion;
            this.fileUrl = res.data.data.uploadData;
          }
        }
      });
    },
    btnSubmit() {
      if (
          this.fileUrl === null ||
          this.fileUrl === undefined ||
          this.fileUrl === ""
      ) {
        this.$message({
          type: "error",
          message: "请上凭证pdf",
        });
        return;
      } else {
        this.$refs.ruleForm.validate((vaild) => {
          if (vaild) {
            console.log("pass...");
            console.log(this.ruleForm);
            const obj = {
              uploadData: this.ruleForm.fileUrl,
              courseId: this.ruleForm.courseId,
              remark: this.ruleForm.remark, // 备注
            }
            this.isDisabled = true;
            uploadEndowments(obj).then(res => {
              if (res.data.code === 0) {
                console.log(res);
                this.$message({
                  type: 'success',
                  message: '成功提交凭证！',
                  onClose: () => {
                    this.endowmentsUploadDialog.show = false
                    this.$emit('getData')
                  }
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.message
                })
                this.isDisabled = false;
              }
            })


            /* this.$router.push({
              path: "certificate",
            }); */
          }
        });
      }
    },
    /* fileList(val) {
      console.log("hahaha:", val);
      this.certificateImgUrl = val;
    }, */
    goBack() {
      this.endowmentsUploadDialog.show = false;
    },
    // 获取收集点门面照片
    getFile(val) {
      this.ruleForm.fileUrl = val;
      this.fileUrl = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.cont {
  margin: 0px auto 0;

  .ruleForm {
    margin-top: 20px;

    .el-form-item__content {
      text-align: left !important;
    }

    .input {
      width: 220px;
    }

    .btn-wrap {
      margin-top: 20px;
    }
  }

  .el-form-item {
    // margin-left: 50px;
  }

  .btn-wrap {
    margin-top: 20px;
    text-align: center;
  }
}
</style>