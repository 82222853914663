<template>
  <div class="pdf-upload">
    <template v-if="uploadedFileUrl">
      <div class="file-info">
        <el-button size="small" type="text" @click="downloadFile">报名表文件.pdf</el-button>
<!--        <div class="file-delete">-->
          <i class="el-icon-delete" @click="deleteFile"/>
<!--        </div>-->
      </div>
    </template>

    <!-- 上传组件，当没有文件上传时显示 -->
    <div v-if="!isSubmit && !uploadedFileUrl">
      <el-upload
          action="#"
          accept="application/pdf"
          :http-request="uploadToCos"
          :on-success="onSuccess"
          :before-upload="beforeUpload"
          :size="size"
          :show-file-list="false"
          list-type="text"
          ref="upload"
      >
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
    </div>
  </div>
</template>
<script>
import {uploadFile} from "@/utils/upload-common";

export default {
  name: 'Upload',

  props: {
    // 最大允许上传个数,默认1，type为2时limit必须 >1
    limit: {
      type: Number,
      default: 1
    },
    // 接受上传的文件类型，非必传
    accept: {
      type: String,
      default: '.png,.jpg,.jpeg'
    },
    // 限制的文件大小，MB为单位，默认20MB，非必传
    size: {
      type: Number,
      default: 10
    },
    /* 上传组件的类型, 默认1
     ** 1：仅支持上传1张图片，上传成功后把上传按钮隐藏，必须删除已经上传的图片才会出现上传按钮，limt必须为1
     ** 2：支持多张上传，上传成功后图片按顺序排列，需 limt > 1
     */
    type: {
      type: Number,
      default: 2
    },
    // 返回绝对路径path/相对路径url，默认path
    way: {
      type: String,
      default: 'path'
    },
    // 上传完成后返回给父组件的路劲数组
    fileArr: {
      type: String,
      default: () => {
        return null
      }
    },
  },

  data() {
    return {
      // 上传的请求头部
      /* headers: {
        authentication:
          'USERID ' +
          base64.encode(
            JSON.parse(getToken()).admin.mobile +
              ':' +
              JSON.parse(getToken()).access_token +
              ':' +
              JSON.parse(getToken()).admin.id
          )
      }, */
      uploadUrl: '/api/admin/common/upload', // 上传接口路径
      uploadedFile: null, // 已上传的文件
      uploadedFileUrl: this.fileArr, // 已上传的文件的url
      file : null,
      isSubmit: false,
      fileList: [],
    }
  },
  created() {
    console.log(this.fileArr);
  },
  watch: {
    fileArr(val) {
      console.log("uploadedFileUrl:" + val);
      this.uploadedFileUrl = val
    }
  },
  methods: {
    deleteFile() {
      this.uploadedFile = null
      this.uploadedFileUrl = ''
      this.isSubmit = false
      this.$emit('input', '')
    },
    onChangeHandle(file) { // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
      this.file = file
      this.fileList = [file]
      // console.log('onChangeHandle file, fileList', file, fileList);
      // this.$refs.upload.$refs["upload-inner"].handleClick();
      // console.log("fileList:", fileList); // add
      // this.$emit("fileList", fileList);
    },
    // 上传文件
    uploadToCos() {
      let files = uploadFile(this.file, (url) => {
        console.log(url);
        this.onSuccess(url)
      });
      console.log(files);
    },

    downloadFile() {
      window.open(this.uploadedFileUrl)
    },


    // 文件上传成功的钩子
    onSuccess(fileUrl) {
      this.uploadedFile = this.file
      this.uploadedFileUrl = fileUrl
      this.$message.success('上传成功!')
      this.$emit('getUploadList', fileUrl)
      // }
    },

    // 上传文件之前的钩子
    beforeUpload(file) {
      this.file = file
      return new Promise((resolve, reject) => {
        const isLtSize = this.size ? file.size / 1024 / 1024 < this.size : true
        if (!isLtSize) {
          this.$message({
            type: 'error',
            message: `文件大小超过限制，最大不超过${this.size}MB`
          })
          reject()
        } else {
          resolve(file)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.image-upload {
  // display: flex;
  // flex-wrap: wrap;
}

.img-img {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  display: none;
}

.img_hover {
  height: 148px;
  width: 148px;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  vertical-align: top;
  margin-bottom: 10px;
  line-height: 148px;
  overflow: hidden;

  img {
    // height: 148px;
    width: 148px;
  }
}

.img_hover:hover > .img-img {
  display: block;
}

.img-img i {
  cursor: pointer;
  font-size: 26px;
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
}

.el-upload-list {
  display: none !important;
}
</style>
