// https://cloud.tencent.com/document/product/436/11459
import COS from "cos-js-sdk-v5";
import { Message } from "element-ui";
import { getCOSSecretKey } from "@/api/common";
let config = {
  videoBucket: "vedio",
  imageBucket: "",
  Region: "",
  origin: ''
};

// 上传到腾讯云cos
export function uploadObject(file, callback) {
  /* 1.获取临时秘钥data
   2.初始化
   3.判断上传文件的类型
   4.判断文件大小 是否需要分片上传*/
    debugger
  const type = file.type.substring(0, 5);
  let fileName = file.name || "";
  const origin_file_name = fileName
    .split(".")
    .slice(0, fileName.split(".").length - 1)
    .join("."); // 获取文件名称
  // console.log('origin_file_name', origin_file_name)
  // 获取当前时间戳 与文件类型拼接 为cos.putObject里的参数Key
  const upload_file_name =
    new Date().getTime() +
    "." +
    fileName.split(".")[fileName.split(".").length - 1]; // 文件上传名称定义为当前时间戳
  // console.log('upload_file_name', upload_file_name)
  // 获取密钥
  getCOSSecretKey({
    bucket: type === "video" ? config.videoBucket : config.imageBucket,
  }).then((res) => {
    // 后台接口返回 密钥相关信息
    const data = res.data.data;
    var credentials = data && data.credentials;
    if (!data || !credentials) return console.error("credentials invalid");
    config.imageBucket = data.bucket
    config.Region = data.region
    config.origin = data.urlPrefix
    console.log('config:', config);
    // 初始化
    var cos = new COS({
      getAuthorization: (options, callback) => {
        callback({
          TmpSecretId: credentials.tmpSecretId,
          TmpSecretKey: credentials.tmpSecretKey,
          XCosSecurityToken: credentials.sessionToken,
          StartTime: data.startTime,
          ExpiredTime: data.expiredTime,
          expiration: data.expiration,
          requestId: data.requestId,
        });
      },
    });
    // 获取上传文件大小
    let size = file.size;
    let key = "/art/" + upload_file_name;
    if (type === "video") {
      // console.log(size / (1024 * 2024))
      if (size / (1024 * 1024) < 50) {
        console.log("视频普通上传");
        cos.putObject(
          {
            Bucket: config.videoBucket, // 存储桶名称
            Region: config.Region, // 存储桶所在地域，必须字段
            Key: key, // 视频名称
            StorageClass: "STANDARD",
            Body: file, // 上传文件对象
            onHashProgress: (progressData) => {
              // console.log('校验中', JSON.stringify(progressData))
              console.log(progressData); // add
            },
            onProgress: (progressData) => {
              const percent = parseInt(progressData.percent * 10000) / 100;
              const speed =
                parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
              console.log("进度：" + percent + "%; 速度：" + speed + "Mb/s;");
            },
          },
          (err, data) => {
            console.log("data", data);
            if (err) {
              console.log("err", err);
              Message({
                message: "视频文件上传失败,请重新上传",
                type: "error",
              });
            } else {
              let fileUrl =
                "https://" + config.videoBucket + ".cos.ap-guangzhou.myqcloud.com" + key;
                console.log('91line:',fileUrl);
              callback(fileUrl, origin_file_name); // 返回视频链接地址和视频的原始名称
            }
          }
        );
      } else {
        console.log("视频分块上传");
        // 上传分块
        cos.sliceUploadFile(
          {
            Bucket: config.videoBucket, // 存储桶名称
            Region: config.Region, // 存储桶所在地域，必须字段
            Key: key /* 必须 */,
            Body: file,
            onTaskReady: (taskId) => {
              /* 非必须 */
              console.log(taskId)
            },
            onHashProgress: (progressData) => {
              /* 非必须 */
              // console.log(JSON.stringify(progressData))
              console.log(progressData); // add
            },
            onProgress: function (progressData) {
              const percent = parseInt(progressData.percent * 10000) / 100;
              const speed =
                parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
              console.log("进度：" + percent + "%; 速度：" + speed + "Mb/s;");
            },
          },
          (err, data) => {
            if (err) {
              console.log(err);
              Message({ message: "文件上传失败,请重新上传", type: "error" });
            } else {
              let fileUrl =
                "https://" + config.videoBucket + ".cos.ap-guangzhou.myqcloud.com" + key;
                console.log('128line:',fileUrl);
              callback(fileUrl, origin_file_name); // 返回视频链接地址和视频的原始名称
            }
            console.log(data); // add
          }
        );
      }
    } else if (type === "image") {
      cos.putObject(
        {
          Bucket: config.imageBucket, // 存储桶名称
          Region: config.Region, // 存储桶所在地域，必须字段
          Key: key, // 图片名称
          StorageClass: "STANDARD",
          Body: file, // 上传文件对象
          onHashProgress: (progressData) => {
            console.log("校验中", JSON.stringify(progressData));
          },
          onProgress: (progressData) => {
            const percent = parseInt(progressData.percent * 10000) / 100;
            const speed =
              parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
            console.log("进度：" + percent + "%; 速度：" + speed + "Mb/s;");
          },
        },
        (err, data) => {
          if (data && data.statusCode == 200) {
            // let fileUrl =
              // "https://" + config.imageBucket + ".cos.ap-guangzhou.myqcloud.com" + key;
              let fileUrl = config.origin + key;
              console.log('155line:',fileUrl);
            callback(fileUrl); // 返回图片链接地址和视频的原始名称
          } else {
            Message({ message: "图片文件上传失败,请重新上传", type: "error" });
          }
        }
      );
    }
  });
}

export function uploadFile(file, callback) {
    /* 1.获取临时秘钥data
     2.初始化
     3.判断上传文件的类型
     4.判断文件大小 是否需要分片上传*/
    debugger
    let fileName = file.name || "";
    // console.log('origin_file_name', origin_file_name)
    // 获取当前时间戳 与文件类型拼接 为cos.putObject里的参数Key
    const upload_file_name =
        new Date().getTime() +
        "." +
        fileName.split(".")[fileName.split(".").length - 1]; // 文件上传名称定义为当前时间戳
    // console.log('upload_file_name', upload_file_name)
    // 获取密钥
    getCOSSecretKey({
        bucket: config.imageBucket,
    }).then((res) => {
        // 后台接口返回 密钥相关信息
        const data = res.data.data;
        var credentials = data && data.credentials;
        if (!data || !credentials) return console.error("credentials invalid");
        config.imageBucket = data.bucket
        config.Region = data.region
        config.origin = data.urlPrefix
        console.log('config:', config);
        // 初始化
        var cos = new COS({
            getAuthorization: (options, callback) => {
                callback({
                    TmpSecretId: credentials.tmpSecretId,
                    TmpSecretKey: credentials.tmpSecretKey,
                    XCosSecurityToken: credentials.sessionToken,
                    StartTime: data.startTime,
                    ExpiredTime: data.expiredTime,
                    expiration: data.expiration,
                    requestId: data.requestId,
                });
            },
        });
        // 获取上传文件大小
        let key = "/pdf/" + upload_file_name;
        cos.putObject(
            {
                Bucket: config.imageBucket, // 存储桶名称
                Region: config.Region, // 存储桶所在地域，必须字段
                Key: key, // 图片名称
                StorageClass: "STANDARD",
                Body: file, // 上传文件对象
                onHashProgress: (progressData) => {
                    console.log("校验中", JSON.stringify(progressData));
                },
                onProgress: (progressData) => {
                    const percent = parseInt(progressData.percent * 10000) / 100;
                    const speed =
                        parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
                    console.log("进度：" + percent + "%; 速度：" + speed + "Mb/s;");
                },
            },
            (err, data) => {
                if (data && data.statusCode == 200) {
                    // let fileUrl =
                    // "https://" + config.imageBucket + ".cos.ap-guangzhou.myqcloud.com" + key;
                    let fileUrl = config.origin + key;
                    console.log('155line:',fileUrl);
                    callback(fileUrl); // 返回图片链接地址和视频的原始名称
                } else {
                    Message({ message: "图片文件上传失败,请重新上传", type: "error" });
                }
            }
        );
    });
}

export default {
  uploadObject,
};
