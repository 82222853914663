<template>
  <div class="main">

    <Head title="我的课程"></Head>

    <div class="el">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="学习中" name="first">

          <template v-if="result.length > 0">
            <div class="listbox">
              <div v-for="item of result" :key="item.courseId" class="Course-item">
                <a href="#" class="Course-item-link">
                  <img :src="item.courseLogo" alt="" class="Course-item-img"/>
                </a>
                <div class="Course-item-info">
                  <div v-if="item.ownType === 2" class="Course-item-title">
                    【企业课程】{{ item.courseName }}
                  </div>
                  <div v-else class="Course-item-title">
                    {{ item.courseName }}
                  </div>
                  <div class="Course-item-progress">
                    <span class="Course-item-progress-text">学习进度</span>
                    <div class="cd-progress">
                      <!-- <div class="progress-bar">
                    <div class="progress-outer">
                      <div class="progress-inner" style="width:0%"></div>
                    </div>
                  </div> -->
                      <!-- <div class="progress-text">0%</div> -->
                      <!-- 进度条 -->
                      <el-progress :percentage="item.finishPercent" color="#43bc60"
                                   define-back-color="#333333"></el-progress>
                    </div>
                  </div>
                </div>
                <div class="Course-item-btn">
                  <!-- <a href="#" class="cd-btn">继续学习</a> -->
                  <el-button
                      v-if="(item.endowmentsStatus === 0 || item.endowmentsStatus === 3) && item.chooseStatus !== 1"
                      @click="goDetail(item.courseId, item.courseType)" type="primary" class="cd-btn">继续学习
                  </el-button>
                  <el-button
                      v-if="item.canBeReplaced && item.ownType !== 2 && (item.endowmentsStatus === 0 || item.endowmentsStatus === 3) && item.chooseStatus !== 1"
                      @click="handleApplyChange(item.courseId, item.exchangeStatus)" type="primary" class="cd-btn">换课申请
                  </el-button>
                  <el-button
                      v-if="item.exchangeStatus === 2 && item.ownType !== 2 && (item.endowmentsStatus === 0 || item.endowmentsStatus === 3) && item.chooseStatus !== 1"
                      type="primary" class="cd-btn">换课中
                  </el-button>
                  <el-button
                      v-if="item.exchangeStatus === 3 && item.ownType !== 2 && (item.endowmentsStatus === 0 || item.endowmentsStatus === 3)  && item.chooseStatus !== 1"
                      @click="handleApplyChange(item.courseId, item.exchangeStatus)" type="warning" class="cd-btn"
                      style="color:#333; border:1px #ddd solid;">
                    申请失败
                  </el-button>
                  <el-button v-if="item.endowmentsStatus === 1"
                             @click="handleEndowments(item.courseId, item.endowmentsStatus)"
                             type="primary" class="cd-btn">提交申请
                  </el-button>
                  <el-button v-if="item.endowmentsStatus === 2"
                             @click="handleEndowments(item.courseId, item.endowmentsStatus)"
                             type="primary" class="cd-btn">申请待审
                  </el-button>
                  <el-button v-if="item.endowmentsStatus === 4"
                             @click="handleEndowments(item.courseId, item.endowmentsStatus)"
                             type="primary" class="cd-btn">审核失败
                  </el-button>
                  <el-button
                      v-if="(item.endowmentsStatus === 0 || item.endowmentsStatus === 3) && item.chooseStatus === 1"
                      @click="handleChoosePeriod(item.courseId)"
                      type="primary" class="cd-btn">课时选修
                  </el-button>
                </div>
              </div>
            </div>
          </template>

          <div v-else class="cd-empty">暂无数据</div>
        </el-tab-pane>
        <el-tab-pane label="收藏" name="third">
          <template v-if="result3.length > 0">
            <div class="listbox">
              <div v-for="item of result3" :key="item.id" class="Course-item">
                <a href="#" class="Course-item-link">
                  <img :src="item.courseLogo" alt="" class="Course-item-img"/>
                </a>
                <div class="Course-item-info">
                  <div class="Course-item-title">
                    {{ item.courseName }}
                  </div>
                  <!-- <div class="Course-item-progress">
                  <span class="Course-item-progress-text">学习进度</span>
                  <div class="cd-progress">
                    <el-progress
                      :percentage="item.finishPercent"
                      color="#43bc60"
                    ></el-progress>
                  </div>
                </div> -->
                </div>
                <div class="Course-item-btn">
                  <!-- <a href="#" class="cd-btn">继续学习</a> -->
                  <el-button @click="goDetail(item.courseId)" type="primary" class="cd-btn">立即学习
                  </el-button>
                  <!-- <el-button v-if="item.canBeReplaced" @click="handleApplyClass" type="warning" class="cd-btn">换课申请</el-button> -->
                </div>
              </div>
            </div>
          </template>
          <div v-else class="cd-empty">暂无数据</div>
        </el-tab-pane>
        <el-tab-pane label="需审核" name="fourth">
          <template v-if="result4.length > 0">
            <div class="listbox">
              <div v-for="item of result4" :key="item.courseId" class="Course-item">
                <a href="#" class="Course-item-link">
                  <img :src="item.courseLogo" alt="" class="Course-item-img"/>
                </a>
                <div class="Course-item-info">
                  <div v-if="item.ownType === 2" class="Course-item-title">
                    【企业课程】{{ item.courseName }}
                  </div>
                  <div v-else class="Course-item-title">
                    {{ item.courseName }}
                  </div>
                </div>
                <div class="Course-item-btn">
                  <!-- <a href="#" class="cd-btn">继续学习</a> -->
                  <el-button v-if="(item.endowmentsStatus === 0 || item.endowmentsStatus === 3)"
                             @click="goDetail(item.courseId, item.courseType)" type="primary" class="cd-btn">继续学习
                  </el-button>
                  <el-button
                      v-if="item.canBeReplaced && item.ownType !== 2 && (item.endowmentsStatus === 0 || item.endowmentsStatus === 3)"
                      @click="handleApplyChange(item.courseId, item.exchangeStatus)" type="primary" class="cd-btn">换课申请
                  </el-button>
                  <el-button
                      v-if="item.exchangeStatus === 2 && item.ownType !== 2 && (item.endowmentsStatus === 0 || item.endowmentsStatus === 3)"
                      type="primary" class="cd-btn">换课中
                  </el-button>
                  <el-button
                      v-if="item.exchangeStatus === 3 && item.ownType !== 2 && (item.endowmentsStatus === 0 || item.endowmentsStatus === 3)"
                      @click="handleApplyChange(item.courseId, item.exchangeStatus)" type="warning" class="cd-btn"
                      style="color:#333; border:1px #ddd solid;">
                    申请失败
                  </el-button>
                  <el-button v-if="item.endowmentsStatus === 1"
                             @click="handleEndowments(item.courseId, item.endowmentsStatus)"
                             type="primary" class="cd-btn">提交申请
                  </el-button>
                  <el-button v-if="item.endowmentsStatus === 2"
                             @click="handleEndowments(item.courseId, item.endowmentsStatus)"
                             type="primary" class="cd-btn">申请待审
                  </el-button>
                  <el-button v-if="item.endowmentsStatus === 4"
                             @click="handleEndowments(item.courseId, item.endowmentsStatus)"
                             type="primary" class="cd-btn">审核失败
                  </el-button>
                  <el-button
                      v-if="(item.endowmentsStatus === 0 || item.endowmentsStatus === 3) && item.chooseStatus === 1"
                      @click="handleChoosePeriod(item.courseId)"
                      type="primary" class="cd-btn">课时选修
                  </el-button>
                </div>
              </div>
            </div>
          </template>

          <div v-else class="cd-empty">暂无数据</div>
        </el-tab-pane>
      </el-tabs>
      <div class="pagination">
        <Pagination :total="search.total" :page.sync="search.page" :limit.sync="search.size" @pagination="getDataList"/>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" title="申请换课" center top="30vh" width="40%" :append-to-body="true">
      <el-form ref="form" :model="applyForm" :rules="rules" label-width="120px">
        <!-- <el-form-item label="申请课程编码" prop="code">
          <el-input v-model="applyForm.code"></el-input>
        </el-form-item> -->
        <el-form-item label="申请课程分类" prop="code">
          <el-cascader
              :show-all-levels="false"
              :props="{ checkStrictly: true }"
              v-model="value" :options="options" @change="handleChange"></el-cascader>
        </el-form-item>
        <el-form-item label="申请课程" prop="code">
          <el-select v-model="applyCourseId" placeholder="请选择">
            <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核意见" v-if="applyForm.suggestion !== ''" prop="reason">
          <el-input type="textarea" disabled="" v-model="applyForm.suggestion"></el-input>
        </el-form-item>
        <el-form-item label="申请原因" prop="reason">
          <el-input type="textarea" v-model="applyForm.reason"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="resetForm">取消</el-button>
        <el-button type="primary" v-if="applyForm.suggestion === ''" @click="submitForm">申请换课</el-button>
        <el-button type="primary" v-else @click="submitForm">重新申请</el-button>
      </div>
    </el-dialog>

    <!-- 上传报名表弹窗 -->
    <EndowmentsUploadDialog
        v-if="endowmentsUploadDialog.show"
        :endowments-upload-dialog="endowmentsUploadDialog"
    />

    <el-dialog :visible.sync="choosePeriodDialog.show" :title="choosePeriodDialog.title" center top="30vh" width="40%"
               :append-to-body="true">
      <div style="margin-bottom: 20px;">
        <span v-if="choosePeriodNum!==0" style="font-size: 16px;color: red">已选选修课节数：{{
            choosenNum
          }}(请选择{{ choosePeriodNum }}节课)</span>
        <span v-if="passPeriodNum!==0"
              style="font-size: 16px;color: red">已选择学时数：{{ choosenPeriodNum }}(请选择{{
            passPeriodNum
          }}个学时)</span>
      </div>
      <el-table :data="periodList" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="类型" align="center" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.isCompulsory === 0">必修</span>
            <span v-else-if="scope.row.isCompulsory === 1">选修</span>
          </template>
        </el-table-column>
        <el-table-column label="学时" align="center" prop="periodNum" width="100" v-if="passPeriodNum!==0">
        </el-table-column>
        <el-table-column label="课时名称" align="center" prop="periodName"/>
      </el-table>
      <div slot="footer">
        <el-button @click="resetChoose">取消</el-button>
        <el-button type="primary" @click="submitChoose">保存选择</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getCourseList} from '@/api/common'
import {applyForExchange, getCollectedCourse, auditDesc} from '@/api/myCenter'
import Pagination from '@/components/Pagination'
import Head from './Head'
import {getClass, getCourseList as getCourseList2, getClassPeriodDetail, saveChoosePeriod} from '@/api/product.js'
import EndowmentsUploadDialog from "./components/EndowmentsUploadDialog";

export default {
  name: 'Course',
  components: {
    Pagination,
    EndowmentsUploadDialog,
    Head
  },
  data() {
    return {
      endowmentsUploadDialog: {},
      choosePeriodDialog: {},
      applyForm: {
        code: '',
        suggestion: '',
      },
      dialogVisible: false,
      rules: {
        code: [
          {required: true, message: '请输入申请课程编码', trigger: 'blur'}
        ]
      },
      listboxKey: 0,
      search: {
        page: 1,
        size: 10,
        total: 0 //分页
      },
      activeName: 'first',
      percentage: 0, //进度条控制
      result: [],
      result2: [],
      result3: [],
      result4: [],
      value: [],
      options: [],
      ids: [],
      selectedIds: [],
      periodList: [],
      choosePeriodNum: 0,
      passPeriodNum: 0,
      choosenNum: 0,
      choosenPeriodNum: 0,
      chooseCourseId: '',
      applyCourseId: '', // 选中的换课id
      options2: []
    }
  },
  watch: {
    'endowmentsUploadDialog.show'(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getDataList(this.activeName)
      }
    }
  },
  mounted() {
    this.getDataList('first')
    this.getClassData();
  },
  methods: {
    handleChange(value) {
      if (value && value.length > 0) {
        this.selectCourse(value[value.length - 1])
      }

    },
    selectCourse(id) {
      let params = {
        categoryId: id,
        page: 1,
        size: 40,
      };
      getCourseList2(params).then((res) => {
        this.options2 = res.data.data.rows.map((item) => {
          return {
            value: item.id,
            label: item.courseName
          }
        })
        //  this.result = res.data.data.rows;
      });
    },
    getClassData() {
      //获取分类
      getClass().then((res) => {
        let loopFun = (data) => {
          data.label = data.categoryName;
          data.value = data.id;
          data.children = data.rows;
          if (data.children && data.children.length > 0) {
            for (let i = 0; i < data.children.length; i++) {
              loopFun(data.children[i]);
            }
          }
        }
        for (let i = 0; i < res.data.data.rows.length; i++) {
          loopFun(res.data.data.rows[i])
        }
        this.options = res.data.data.rows
      })
    },
    //获取课程分页数据
    getDataList(type) { // type=first, second
      console.log("type", type)
      if (type === 'first') {
        delete this.search.needEndowments
      } else if (type === 'fourth') {
        this.search.needEndowments = true
      }
      getCourseList(this.search).then((res) => {
        if (res.data.code === 0) {
          this.search.total = res.data.data.total
          if (this.activeName === 'first') {
            this.result = res.data.data.rows
            console.log("res.data.", res.data)
          }
          if (this.activeName === 'second') {
            this.result2 = res.data.data.rows
          }
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    //获取收藏课程分页数据
    getCollectedList() {
      delete this.search.categoryId
      delete this.search.finishFlag
      getCollectedCourse(this.search).then((res) => {
        if (res.data.code === 0) {
          this.search.total = res.data.data.total
          this.result3 = res.data.data.rows
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    getNeedEndowmentsList() {
      this.search.needEndowments = true
      getCourseList(this.search).then((res) => {
        if (res.data.code === 0) {
          this.search.total = res.data.data.total
          this.result4 = res.data.data.rows
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    handleClick(tab) {
      console.log(tab.name)
      this.search.page = 1
      this.search.size = 10
      this.search.total = 0
      this.result = []
      if (tab.name === 'first') {
        this.getDataList('first',
            Object.assign(this.search, {
              finishFlag: false
            }),
        )
      }
      if (tab.name === 'second') {
        this.getDataList('second',
            Object.assign(this.search, {
              finishFlag: true
            })
        )
      }
      if (tab.name === 'third') {
        this.getCollectedList()
      }
      if (tab.name === 'fourth') {
        this.getNeedEndowmentsList()
      }
    },
    //继续学习
    goDetail(courseId, courseType) {
      this.$router.push({
        path: `/classdetail?id=${courseId}&type=${courseType}`
      })
    },
    // 换课申请
    handleApplyClass(id) {
      let courseId = JSON.stringify(id)
      sessionStorage.setItem('courseId', courseId)
      this.$router.push({
        path: '/'
      })
    },
    handleApplyChange(id, exchangeStatus) {
      const _this = this;
      this.applyForm.preCourseId = id;
      const params = {
        courseId: id
      }
      console.log("exchangeStatus", exchangeStatus)
      if (exchangeStatus === 3) {
        auditDesc(params).then(res => {
          if (res.data.code === 0) {
            console.log(res.data)
            _this.applyForm.code = res.data.data.prevCourseCode
            _this.applyForm.showDesc = res.data.data.showDesc
            _this.applyForm.suggestion = res.data.data.auditDesc
            console.log("_this.applyForm.showDesc", _this.applyForm.suggestion)
          }
          this.dialogVisible = true;
        })
      } else {
        this.dialogVisible = true;
      }
    },
    handleEndowments(courseId, endowmentsStatus) {
      this.endowmentsUploadDialog.show = true;
      this.endowmentsUploadDialog = {
        show: true,
        title: "报名表提交",
        courseId: courseId,
        status: endowmentsStatus
      };
    },
    handleSelectionChange(selection) {
      console.log("this.selectedIds", selection)
      this.selectedIds = selection.map(item => item.id)
      let num = 0;
      let period = 0;
      for (let i = 0; i < selection.length; i++) {
        num++;
        period += selection[i].periodNum;
        console.log("num", num)
      }
      this.choosenNum = num;
      this.choosenPeriodNum = period;
    },
    resetChoose() {
      this.choosePeriodDialog.show = false;
      this.ids = [];
      this.choosePeriodNum = 0;
      this.passPeriodNum = 0;
      this.periodList = [];
      // getClassPeriodDetail(this.chooseCourseId).then(res=>{
      //   if(res.data.code===0){
      //     this.ids = res.data.data.ids;
      //     this.passPeriodNum = res.data.data.passPeriodNum;
      //     this.chooseCourseId = res.data.data.courseId;
      //   }
      // })
    },
    submitChoose() {
      if (this.choosePeriodNum !== 0 && this.choosenNum !== this.choosePeriodNum) {
        this.$message({
          type: 'error',
          message: `请选择${this.choosePeriodNum}节课`
        })
        return
      }
      if (this.passPeriodNum !== 0 && this.choosenPeriodNum !== this.passPeriodNum) {
        this.$message({
          type: 'error',
          message: `请选择${this.passPeriodNum}个学时`
        })
        return
      }
      const obj = {
        courseId: this.chooseCourseId,
        ids: this.selectedIds
      }
      saveChoosePeriod(obj).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: `选课成功`,
          })
          this.choosePeriodDialog.show = false;
          this.resetChoose();
          this.getDataList(this.activeName)
        }
      })
    },
    handleChoosePeriod(courseId) {
      this.choosePeriodDialog.show = true;
      this.chooseCourseId = courseId;
      getClassPeriodDetail(courseId).then(res => {
        if (res.data.code === 0) {
          this.periodList = res.data.data.periodList;
          this.ids = res.data.data.ids;
          this.choosePeriodNum = res.data.data.choosePeriodNum;
          this.passPeriodNum = res.data.data.passPeriodNum;
          this.chooseCourseId = res.data.data.courseId;
        }
      })
      this.choosePeriodDialog = {
        show: true,
        title: "课时选修",
        courseId: courseId,
      };
    },
    resetForm() {
      this.dialogVisible = false
      this.$refs['form'].resetFields();
    },
    submitForm() {
      //课程id不能为空
      if (this.applyCourseId.length == 0) {
        this.$message({
          type: 'error',
          message: '请选择课程'
        })
        return
      }
      const obj = {
        preCourseId: this.applyForm.preCourseId, //换课前课程id
        applyCourseId: this.applyCourseId, //期望更换的课程id
        applyReason: this.applyForm.reason // 申请原因 可选
      }
      applyForExchange(obj).then(res => {
        console.log("applyForExchange", res.data)
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: `申请换课成功`,
          })
          this.dialogVisible = false
          this.resetForm();
        } else {
          this.$message({
            type: 'error',
            message: res.data.message,
          })
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.my-dialog {
  max-width: 800px;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-dialog .el-dialog__body {
  max-height: calc(100% - 60px);
  overflow-y: auto;
}

.main {
  background: #fff;

  span {
    display: flex;
  }

  .el {
    border-top: 1px solid #f5f5f5;
    margin-top: 10px;
    // min-height: 62vh;

    .listbox {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

    }

    .Course-item {
      padding: 10px;
      margin-bottom: 10px !important;
      border: 1px solid #eee;
      margin: 10px 0;
      height: 128px;
      border-radius: 3px;
      flex-shrink: 0;
      width: 860px;
      display: flex;
      position: relative;
      background: url('../../../assets/yuming-bg.jpg') -900px 377px repeat-y #fff;

      // background-size: 40%;
      a {
        text-decoration: none;
      }

      .Course-item-link {
        position: relative;
        margin-right: 24px;
        width: 227px;
        height: 128px;
        overflow: hidden;

        img {
          border: 0;
          vertical-align: middle;
        }

        .Course-item-img {
          width: 227px;
        }
      }

      .Course-item-info {
        flex: 1;
        margin-top: 8px;


        .Course-item-title {
          // width: 45%;
          font-size: 16px;
          line-height: 28px;
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: normal;
          text-align: left;
          font-weight: bold;
          margin-bottom: 25px;

          .cd-link-major {
            color: rgba(0, 0, 0, 0.88);
          }
        }

        .Course-item-progress {
          width: 400px;
          color: rgba(0, 0, 0, 0.56);

          .Course-item-progress-text {
            float: left;
            width: 15%;
            font-size: 14px;
          }

          .cd-progress {
            float: left;
            width: 80%;
            margin-bottom: 0;
            position: relative;

            .progress-bar {
              width: 96%;
              box-sizing: border-box;
              display: inline-block;
              padding-right: 40px;
              margin-right: -40px;
              background: none;
              box-shadow: none;
              float: left;
              height: 100%;
              font-size: 12px;
              color: #fff;
              text-align: center;

              .progress-outer {
                width: 96%;
                border-radius: 6px;
                display: inline-block;
                background-color: rgba(0, 0, 0, 0.08);

                .progress-inner {
                  height: 4px;
                }
              }

              .progress-text {
                width: 42px;
                font-size: 16px !important;
                color: #919191;
                display: inline-block;
                vertical-align: middle;
                margin-left: 4px;
                text-align: left;
                line-height: 1;
              }
            }
          }
        }
      }

      .Course-item-btn {
        margin-left: 10px;
        position: absolute;
        top: 0px;
        right: 60px;
        width: 90px;
        display: flex;
        flex-direction: column;
        height: 150px;
        justify-content: center;

        .cd-btn {
          color: #fff;
          /* background: #43bc60; */
          border-color: #43bc60;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          touch-action: manipulation;
          cursor: pointer;
          white-space: nowrap;
          user-select: none;
          font-size: 14px;
          font-weight: 500;
          padding: 9px 12px;
          line-height: 1;
          border: 1px solid transparent;
          border-radius: 4px;
          outline: none;
          margin: 10px 0px;

        }
      }
    }
  }

  .cd-empty {
    text-align: center;
    color: rgba(0, 0, 0, 0.32);
    padding: 20px 0;
    // min-height: 62vh;
  }

  .pagination {
    margin-top: 30px;
  }
}

.courseTitle {
  display: block;
  // width: 100%;
  line-height: 60px;
  padding: 0 20px;
  height: 60px;
  background: url('../../../assets/hot_re_Bg.png') left top no-repeat #fff;
  font-weight: bold;
}

.el {
  padding: 10px 20px;
  margin-top: 0px !important;
}
</style>
